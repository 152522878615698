import { Heart, HeartFilled, MapPin } from "@/assets/Icons";
import { getOwnerShipStatus } from "@/common/product";
import {
  MAXIMUM_TENURE,
  MINIMUM_DOWN_PAYMENT,
  RATE_OF_INTEREST_DEFAULT_VALUE,
} from "@/utils/constants";
import { emi_calculator } from "@/utils/emi";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { getCurrentDate } from "@/utils/date";
import ProductLabel from "./ProductLabel";
import Image from "../ui/image";

interface IProps {
  className?: string;
  product: IVehicleInfo;
  containerRef?: any;
  index: number;
  handleClick: (product: any, index: number) => void;
  handleWishListClick?: (e: React.SyntheticEvent, product: any) => void;
  handleBookTestDriveClick?: (
    e: React.SyntheticEvent,
    product: any,
    hasPassedScheduleDate: boolean,
  ) => void;
  wishlistPage?: boolean;
  wishList?: number[];
  returnCustomer?: boolean;
  showEmi?: boolean;
  showStoreName?: boolean;
  showWishList?: boolean;
  vehicleAttributesClassName?: string;
  removeYearFromVehicleAttributes?: boolean;
  label?: string;
}

const ProductCard = ({
  className,
  handleClick,
  handleWishListClick,
  containerRef,
  product,
  index,
  wishList,
  handleBookTestDriveClick,
  wishlistPage = false,
  returnCustomer = false,
  showEmi = true,
  showStoreName = true,
  showWishList = true,
  vehicleAttributesClassName = "",
  removeYearFromVehicleAttributes = false,
  label,
}: IProps) => {
  const currentDate = getCurrentDate();
  const hasPassedScheduleDate = currentDate > product?.preferredDate;

  return (
    <div
      data-test-id="plp-product-card"
      ref={containerRef}
      key={index}
      onClick={() => handleClick(product, index + 1)}
      className={cn(
        "shadow-sm rounded-[8px] bg-white overflow-hidden",
        !returnCustomer && "border-[1px] border-neutral50",
        className,
      )}
    >
      <div className="relative">
        <Image
          className={cn(
            "w-full h-60 object-cover object-center",
            product?.isAvailability === false && "blur-[3px]",
          )}
          transform="q-90"
          srcSet={`${product.imagePaths?.[0]}?tr=w-350 350w, ${product.imagePaths?.[0]}?tr=w-380 380w`}
          sizes="(max-width: 600px) 350px, 380px"
          src={product.imagePaths?.[0] as string}
          alt={product.vehicleName}
        />
        {product?.isAvailability === false && (
          <p className="text-sm font-semibold text-black text-center absolute w-full top-[208px]  bg-[#DADADA] py-2">
            Sold Out
          </p>
        )}
        {(product?.drivexAssured || label) && <ProductLabel label={label} />}
        {showWishList && (
          <div
            id="wishlist"
            className={cn(
              "absolute top-3.5 right-3.5 bg-white p-3 rounded-md",
              product?.isAvailability === false && "blur-[3px]",
            )}
            onClick={(e) =>
              handleWishListClick && handleWishListClick(e, product)
            }
          >
            {product && wishList?.includes(product?.vehicleId) ? (
              <HeartFilled size={25} />
            ) : (
              <Heart size={25} />
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-3 p-4 bg-white">
        <p className={cn("text-xl", returnCustomer && "line-clamp-1")}>
          {product?.vehicleName}
        </p>
        <p className={cn("text-neutral300", vehicleAttributesClassName)}>
          {product?.registrationNumber && `${product?.registrationNumber} •`}{" "}
          {product?.kmDriven?.displayValue} KMs •{" "}
          {getOwnerShipStatus(product?.ownerShip)} • {product?.manufactureYear}{" "}
          {removeYearFromVehicleAttributes ? "" : "Year"}
        </p>

        <div
          className={cn(
            !returnCustomer && showStoreName && "border-b-2",
            "flex-between border-neutral50 pb-2.5 items-center",
          )}
        >
          <p className="text-2xl text-primaryA2">
            ₹{product?.price?.displayValue}
          </p>

          {showEmi && (
            <p className="text-[15px] text-right text-neutral300">
              {!returnCustomer && <>EMI starts with &nbsp;</>}
              <span className="underline underline-offset-[2px] text-primaryA2 decoration-orange-500 text-base font-semibold">
                ₹
                {emi_calculator(
                  product?.price?.value -
                    (MINIMUM_DOWN_PAYMENT / 100) * product?.price?.value,
                  RATE_OF_INTEREST_DEFAULT_VALUE,
                  MAXIMUM_TENURE / 12,
                ).toLocaleString("en-IN")}
                /m
              </span>
            </p>
          )}
        </div>

        {showStoreName && !returnCustomer && (
          <div className="flex gap-2">
            <MapPin colorOne="#919191" colorTwo="#919191" size={20} />
            <p className="text-neutral300 font-semibold text-sm capitalize">
              {product?.storeName}
            </p>
          </div>
        )}
        {wishlistPage && product?.isAvailability === true && (
          <Button
            variant="outline"
            className={cn(
              "bg-primaryA2 border-none text-white text-base py-6 mt-2 hover:bg-primaryA2 hover:text-white",
              product?.isScheduled &&
                !hasPassedScheduleDate &&
                "bg-neutral30 flex flex-col gap-[1px] py-8 text-xs hover:bg-neutral30",
            )}
            onClick={(e) =>
              handleBookTestDriveClick &&
              handleBookTestDriveClick(e, product, hasPassedScheduleDate)
            }
          >
            {product?.isScheduled && !hasPassedScheduleDate ? (
              <>
                <p className="text-primaryA2 text-base">Upcoming Test Drive</p>
                <p className="text-neutral300 text-base">
                  {product?.preferredDate}
                </p>
              </>
            ) : (
              "Book Test Drive"
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
