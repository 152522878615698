import { Button } from "@/components/ui/button.tsx";
import React from "react";
import { cn } from "@/lib/utils";
import Image from "../ui/image";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { HorizontalGradient, VerticalGradient } from "@/assets/Gradient";
import Video from "../ui/video";

interface IProps {
  data_test_id?: string;
  stepsData: {
    index: any;
    heading: string;
    description: string;
  }[];
  media: string;
  heading: string | React.ReactNode;
  type?: string;
  buttonText: string;
  handleCTAClick: () => void;
}

const ProcessSteps = ({
  data_test_id,
  stepsData,
  media,
  heading,
  type,
  buttonText,
  handleCTAClick,
}: IProps) => {
  const parentEle: HTMLElement | null = document.getElementById("container");
  const parentHeight = parentEle?.offsetHeight;
  const { isDesktop } = useScreenDetector();

  const isVideo = media.includes("mp4");

  return (
    <section
      className="flex flex-col lg:flex-row-reverse justify-between items-center bg-neutral900 2xl:max-w-7xl w-full mx-auto"
      id="container"
    >
      {isVideo ? (
        <div className="w-full relative">
          <Video
            src={media as string}
            className={cn("w-full object-cover object-center")}
            style={{
              height: isDesktop ? parentHeight : "100%",
            }}
          />
          {isDesktop ? (
            <span className="absolute top-0 left-[-5px] h-full">
              <VerticalGradient height={parentHeight || 0} />
            </span>
          ) : (
            <span className="absolute bottom-[-5px] w-full">
              <HorizontalGradient width="100%" />
            </span>
          )}
        </div>
      ) : (
        <Image
          transform="f-webp"
          src={media as string}
          alt="BuyCTA"
          className={cn("w-full lg:w-1/2 object-cover")}
          style={{
            height: isDesktop ? parentHeight : "100%",
          }}
        />
      )}

      <div className="flex flex-col w-full justify-center items-center gap-2 my-8">
        <div>
          <p className="text-white text-left text-2xl md:text-4xl font-semibold pl-4 lg:my-2">
            {heading}
          </p>

          <div
            className={cn(
              type === "grid"
                ? "grid grid-cols-2 gap-3 lg:gap-6 p-4"
                : "flex flex-col gap-6 p-4",
            )}
          >
            {stepsData.map((step, index) => (
              <div
                key={index}
                className={cn(
                  "flex rounded-md gap-4 p-4 bg-[#2F2F2F] lg:max-w-[400px]",
                  type === "grid" && "lg:max-w-[260px]",
                )}
              >
                {type !== "grid" && (
                  <p className="text-3xl self-center text-primaryA2">
                    {step.index}
                  </p>
                )}
                <div className="flex flex-col gap-2">
                  {type === "grid" && (
                    <p className="text-3xl text-primaryA2 my-2">{step.index}</p>
                  )}
                  <p className="text-white">{step.heading}</p>
                  <p className="text-neutral300">{step.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-center w-full p-4">
            <Button
              data-test-id={data_test_id}
              variant="outline"
              className="bg-primaryA2 border-none text-white font-bold md:max-w-52 w-full mx-auto"
              onClick={() => handleCTAClick()}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProcessSteps;
