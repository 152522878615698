import CustomerDetail from "@/components/CustomerDetail";
import Image from "@/components/ui/image";
import { cn } from "@/lib/utils";
import { LoaderIcon } from "lucide-react";

const SoldSuccess = ({ orderDetails, handleDownload }: any) => {
  const isDocumentAvailable =
    orderDetails?.order_status === "COMPLETED" &&
    orderDetails?.delivery_note_path;
  // orderDetails?.invoice_pdf

  return (
    <div
      className={cn(
        "flex flex-col gap-4 mt-[70px] px-3",
        !orderDetails && "flex-center h-full mb-[70px]",
      )}
    >
      {!orderDetails ? (
        <div>
          <LoaderIcon size={60} className="animate-spin" color="#F47823" />
        </div>
      ) : (
        <>
          <div className="flex flex-col items-center gap-1 py-2">
            <p>Sold for</p>
            <p className="text-primaryA2 font-semibold text-4xl">
              ₹{orderDetails?.price?.displayValue}.
              <span className="text-base">00</span>
            </p>
          </div>

          <div className="flex gap-4 items-center border-y py-4">
            <>
              <Image
                className="w-[92px] h-[64px] rounded-lg object-cover"
                src={orderDetails?.imagePaths[0]}
                alt="Vehicle"
              />

              <div className="flex flex-col gap-1">
                <p className="text-sm">{orderDetails?.vehicleName}</p>
                <p className="text-xs text-neutral300">
                  {orderDetails?.registrationNumber} •{" "}
                  {orderDetails?.kmDriven?.displayValue} KMs •{" "}
                  {orderDetails?.manufactureYear}
                </p>
                <p className="text-sm text-neutral300">
                  Sold on {orderDetails?.order_created_at}
                </p>
              </div>
            </>
          </div>

          <CustomerDetail
            orderDetails={orderDetails}
            showDocuments={isDocumentAvailable}
            showPendingMessage={!isDocumentAvailable}
            handleDownload={handleDownload}
          />
        </>
      )}
    </div>
  );
};

export default SoldSuccess;
