import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { getDecadeRange } from "@/utils/date";

const SelectYear = ({
  saveVehicleDetails,
  handleSelectValueChange,
}: {
  saveVehicleDetails: any;
  handleSelectValueChange: (value: string) => void;
}) => {
  const decadeRange = getDecadeRange();
  const manufacturingYear = saveVehicleDetails?.manufactureYear;

  return (
    <>
      <Select
        value={manufacturingYear ? manufacturingYear.toString() : ""}
        onValueChange={handleSelectValueChange}
      >
        <SelectTrigger className="w-full py-6 font-semibold text-base data-[placeholder]:text-black data-[placeholder]:font-semibold bg-neutral30 focus-visible:ring-0 focus-visible:ring-offset-0 border-none">
          <SelectValue
            placeholder={manufacturingYear ? manufacturingYear : "Year"}
          />
        </SelectTrigger>
        <SelectContent>
          {decadeRange.map((year, index) => (
            <SelectItem
              key={index}
              className="focus:bg-neutral30 text-base font-semibold"
              value={year.toString()}
            >
              {year}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default SelectYear;
