import { useNavigate } from "react-router-dom";
import { ArrowUpRightIcon } from "@/assets/Icons";
import aa from "search-insights";
import { algoliaConfigs, initialize_aloglia } from "@/utils/algolia/actions";
import { SEARCH_RESULT_CLICK_EVENT } from "@/utils/algolia/events";
import { useEffect } from "react";
import { track } from "@/utils/mixpanel/actions";
import { SEARCH_SUGGESTION_CLICKED } from "@/utils/mixpanel/Events/search_clicked_events";
import { useSelector } from "react-redux";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { isArrayOfStrings } from "@/utils/object";
import { getOwnerShipStatus } from "@/common/product";
import { cn } from "@/lib/utils";

interface IProps {
  searchData: { vehicleId: number; modelName: string }[];
  queryId: string;
  showAttributes: boolean;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchResults = ({
  searchData = [],
  queryId,
  setOpenSearch,
  showAttributes = false,
}: IProps) => {
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const { location } = useSelector((state: any) => state.location);
  const isDealerPage = window.location.pathname.includes("dealer");

  useEffect(() => {
    initialize_aloglia();
  }, []);

  const updateRecentSearch = (vehicleData: {
    brand: string;
    modelName: string;
    vehicleName: string;
  }) => {
    let recentSearches =
      JSON.parse(localStorage.getItem("recentSearches") as string) || [];

    if (isArrayOfStrings(recentSearches)) {
      recentSearches = recentSearches.filter(
        (item: any) => typeof item !== "string",
      );
    }

    // Remove vehicle name from array if vehicle name already exists
    if (
      recentSearches.some(
        (item: { [x: string]: any }) =>
          item?.vehicleName === vehicleData?.vehicleName,
      )
    ) {
      const index = recentSearches.findIndex(
        (obj: { vehicleName: string }) =>
          obj.vehicleName === vehicleData?.vehicleName,
      );

      recentSearches.splice(index, 1);
    }

    // Remove last element from array if recentSearches already has 5 elements
    if (recentSearches.length >= 5) {
      recentSearches.pop();
    }

    // Add item to the start of the array & update localstorage
    recentSearches.unshift(vehicleData);
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  };

  const handleClick = (
    data: {
      brand: string;
      modelName: string;
      objectID: string;
      vehicleName: string;
      vehicleId: number;
    },
    index: number,
  ) => {
    if (isDealerPage) {
      navigate(`/dealer/vehicles/${data.vehicleId}`, {
        replace: true,
      });
      setOpenSearch(false);
      return;
    }

    updateRecentSearch({
      brand: data?.brand,
      modelName: data?.modelName,
      vehicleName: data?.vehicleName,
    });
    aa("sendEvents", [
      algoliaConfigs({
        eventName: `${SEARCH_RESULT_CLICK_EVENT}:${data.vehicleName}`,
        queryID: queryId,
        objectIDs: [`${data.objectID}`],
        positions: [index],
        eventType: "click",
        index: "reachX-search-engine-dev",
      }),
    ]);
    track(SEARCH_SUGGESTION_CLICKED, {
      suggestion_term: data.vehicleName,
      city_name: location,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    setOpenSearch(false);
    navigate(`/${city}/buy-two-wheelers?q=${data.vehicleName}`, {
      replace: true,
    });
  };

  return (
    <div className="flex flex-col gap-3 overflow-auto">
      <p className="text-neutral300 text-sm">
        Search Results {`${searchData.length}`}
      </p>

      <div className="flex flex-col gap-3 overflow-auto">
        {searchData &&
          searchData.map((data: any, index) => (
            <>
              <div
                data-test-id="search-test-results"
                key={data.vehicleId || index}
                className={cn(
                  "border-b leading-[60px] flex-between pb-2.5 pr-1",
                  showAttributes && "flex-col gap-2",
                )}
                onClick={() => handleClick(data, index + 1)}
              >
                <p className="text-base">{data.vehicleName}</p>
                {!showAttributes && <ArrowUpRightIcon />}
                {showAttributes && (
                  <p className="text-neutral300 text-sm">
                    {data?.registrationNumber &&
                      `${data?.registrationNumber} •`}{" "}
                    {data?.kmDriven?.displayValue} KMs •{" "}
                    {getOwnerShipStatus(data?.ownerShip)} •{" "}
                    {data?.manufactureYear}{" "}
                  </p>
                )}
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default SearchResults;
