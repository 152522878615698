import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ChevronLeft as LeftIcon } from "lucide-react";
import { Input } from "../../components/ui/input";
import { useEffect, useRef, useState } from "react";
import PopularOrRecentSearches from "./PopularOrRecentSearches";
import PopularBrands from "./PopularBrands";
import SearchResults from "./SearchResults";
import {
  usePopularSearchResultsQuery,
  useLazySearchResultsQuery,
} from "@/store/services/search";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import Location from "@/components/Location";
import useDebouncedApiCall from "@/hooks/useDebounce";
import {
  DEBOUNCE_DELAY,
  PLACEHOLDER_TEXTS,
  PLACEHOLDER_TEXT_INTERVAL,
} from "@/utils/constants";
import { SearchIcon } from "@/assets/Icons";
import { cn } from "@/lib/utils";
import { track } from "@/utils/mixpanel/actions";
import { BACKBUTTON_CLICKED_EVENT } from "@/utils/mixpanel/Events/backButton_clicked_events";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import { SEARCH_INPUT_SUBMITTED } from "@/utils/mixpanel/Events/search_clicked_events";
import AnimatedLabel from "@/components/AnimatedLabel";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import Chips from "@/components/SkeletonLoaders/Chips";
import { isArrayOfStrings } from "@/utils/object";
import {
  useGetSoldVehiclesQuery,
  useGetStoreVehiclesQuery,
} from "@/store/services/Dmx/storeVehicles";
import { DX_Search_Floor_Stock } from "@/utils/mixpanel/DMXEvents/dx_search_events";
import { getPlaceholder } from "@/utils/url";

const Search = ({
  setOpenSearch,
  showRecentSearches,
  showPopularSearches,
  showPopularBrands,
  showStores = false,
  showSelectCity = true,
}: {
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  showRecentSearches: boolean;
  showPopularSearches: boolean;
  showPopularBrands: boolean;
  showSelectCity: boolean;
  showStores: boolean;
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [openLocation, setOpenLocation] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(true);
  const inputRef = useRef<any>(null);
  const user = useSelector((state: any) => state.user.currentUser);
  const { location } = useSelector((state: any) => state.location);
  const [getSearchResults, { data: searchResultData }] =
    useLazySearchResultsQuery();
  const isDealerPage = window.location.pathname.includes("dealer");
  const { data: popularData = [], isFetching } = usePopularSearchResultsQuery(
    {
      cityName: location,
    },
    { skip: isDealerPage },
  );
  const store = useSelector((state: any) => state.store.store);
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  const role = localStorage.getItem("role");
  const pathURL = useLocation();
  const pageSource = getPageSource(pathURL.pathname);
  const debounce = useDebouncedApiCall<object>(
    getSearchResults,
    DEBOUNCE_DELAY,
  );
  const isSoldVehiclesPage = pathURL.pathname.includes("sold-vehicles");
  const [queryFunction, setQueryFunction] = useState(() =>
    isSoldVehiclesPage ? useGetSoldVehiclesQuery : useGetStoreVehiclesQuery,
  );
  const { data: dmxProductResultsData } = queryFunction(
    {
      store_id: store?.store_id,
    },
    { skip: !store?.store_id },
  );
  const searchData = isDealerPage
    ? isSoldVehiclesPage
      ? dmxProductResultsData?.data?.orders
      : dmxProductResultsData?.data?.vehicles
    : searchResultData?.data?.vehicles;
  const queryId = searchResultData?.data?.queryId;
  const popularSearchData = popularData.data || [];
  const recentSearchData =
    JSON.parse(localStorage.getItem("recentSearches") as string) || [];
  const filteredSearchData =
    isDealerPage &&
    searchData?.filter(
      (item: any) =>
        item.vehicleName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
        item.registrationNumber
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
    );

  useEffect(() => {
    setQueryFunction(() =>
      isSoldVehiclesPage ? useGetSoldVehiclesQuery : useGetStoreVehiclesQuery,
    );
  }, [pathURL]);

  if (openLocation) {
    return (
      <Dialog open={openLocation}>
        <Location setOpenLocation={setOpenLocation} />
      </Dialog>
    );
  }

  const handleBackClick = () => {
    setOpenSearch(false);
    track(BACKBUTTON_CLICKED_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigate(-1);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    if (!isDealerPage && e.target.value) {
      debounce({ cityName: location, searchQuery: e.target.value });
    }
  };

  const handleTracking = () => {
    if (role !== null) {
      track(DX_Search_Floor_Stock, {
        user_id: user?.id,
        role: role,
        search_query: searchValue,
      });

      return;
    }

    track(SEARCH_INPUT_SUBMITTED, {
      search_term: searchValue,
      city_name: location,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <>
      <Dialog open={true}>
        <DialogContent
          data-test-id="Search-dialog"
          className="sm:max-w-[425px] flex flex-col sm:h-[calc(100%-10rem)] overflow-y-scroll h-full gap-7 lg:max-w-4xl"
        >
          <div className="flex-between items-center">
            <div
              data-test-id="search-back-click"
              className="flex gap-2"
              onClick={() => handleBackClick()}
            >
              <LeftIcon />
              <p>Find Your Perfect Ride</p>
            </div>

            {showStores && ""}

            {showSelectCity && (
              <DialogTrigger asChild>
                <Button
                  data-test-id="search-select-city"
                  onClick={() => setOpenLocation(true)}
                  variant="outline"
                  className="bg-neutral30 border-none"
                >
                  <span className="text-primaryA2">
                    {location || "Select city"}
                  </span>
                </Button>
              </DialogTrigger>
            )}
          </div>

          <div
            onClick={() => inputRef?.current?.focus()}
            className={cn(
              "flex items-center px-4 rounded-md bg-neutral30 border-none relative",
            )}
          >
            <SearchIcon />

            {!isInputFocused && !isDealerPage && (
              <AnimatedLabel
                texts={PLACEHOLDER_TEXTS}
                interval={PLACEHOLDER_TEXT_INTERVAL}
                label="Search for"
              />
            )}

            <Input
              ref={inputRef}
              data-test-id="Search-input"
              className="bg-neutral30 border-none focus-visible:ring-0 focus-visible:ring-offset-0"
              onChange={handleSearch}
              value={searchValue}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleTracking();
                  setOpenSearch(false);
                  if (isDealerPage) {
                    navigate(
                      isSoldVehiclesPage
                        ? `/dealer/sold-vehicles?q=${searchValue}`
                        : `/dealer/vehicles?q=${searchValue}`,
                      {
                        replace: true,
                      },
                    );
                  } else {
                    navigate(`/${city}/buy-two-wheelers?q=${searchValue}`, {
                      replace: true,
                    });
                  }
                }
              }}
              onBlur={() => setIsInputFocused(false)}
              onFocus={() => setIsInputFocused(true)}
              autoFocus
              placeholder={isDealerPage ? getPlaceholder() : undefined}
            />
          </div>

          {searchValue && (
            <SearchResults
              searchData={isDealerPage ? filteredSearchData : searchData}
              queryId={queryId}
              setOpenSearch={setOpenSearch}
              showAttributes={isDealerPage ? true : false}
            />
          )}

          {!searchValue && (
            <>
              {showRecentSearches &&
                recentSearchData?.length > 0 &&
                !isArrayOfStrings(recentSearchData) && (
                  <PopularOrRecentSearches
                    setOpenSearch={setOpenSearch}
                    data={recentSearchData}
                    type="recent"
                  />
                )}

              {showPopularSearches &&
                (isFetching ? (
                  <div className="flex flex-col gap-3">
                    <Chips className="max-w-36 h-8 rounded" />
                    <div className="flex flex-wrap gap-3 justify-start">
                      {Array.from({ length: 4 }, (_, index) => (
                        <Chips className="max-w-36 h-11 rounded" key={index} />
                      ))}
                    </div>
                  </div>
                ) : (
                  popularSearchData.length > 0 && (
                    <PopularOrRecentSearches
                      setOpenSearch={setOpenSearch}
                      data={popularSearchData}
                      type="popular"
                    />
                  )
                ))}

              {showPopularBrands && (
                <PopularBrands setOpenSearch={setOpenSearch} />
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Search;
