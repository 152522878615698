import { Button } from "@/components/ui/button";
import { dealerBenefits } from "./data";
import Image from "@/components/ui/image";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";

interface IProps {
  becomeADealer: any;
  handleApplyNowClick: () => void;
  handleCheckEligibilityClick: () => void;
}

const JoinTheMobility = ({
  becomeADealer,
  handleApplyNowClick,
  handleCheckEligibilityClick,
}: IProps) => {
  const { isDesktop } = useScreenDetector();
  return (
    <>
      <div className="flex justify-center md:justify-around items-center max-w-7xl mx-auto w-full md:my-20">
        <div
          className={cn("flex flex-col gap-4 lg:gap-6", !isDesktop && "w-full")}
        >
          <p className="text-white text-center lg:text-left lg:leading-[2.8rem] text-2xl md:text-3xl font-semibold">
            Race Forward with <br className="sm:hidden" /> DriveX{" "}
            <br className="hidden sm:block" />
            <span className="text-primaryA2">
              Enroll as Our <br className="sm:hidden" /> Dealer
            </span>
          </p>

          <div className="flex gap-4 lg:gap-6 py-4 justify-center">
            {dealerBenefits.map((info, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row items-center text-center lg:text-left gap-4"
              >
                <span className="h-12 w-12 bg-accent rounded-full flex-center">
                  {info.icon}
                </span>
                <p className="font-semibold text-sm text-white lg:text-base">
                  {info.title}
                </p>
              </div>
            ))}
          </div>

          {becomeADealer && (
            <Image
              transform="f-webp"
              src={
                becomeADealer?.data[0]?.attributes?.Image?.data?.attributes?.url
              }
              alt={`${becomeADealer?.data[0]?.attributes?.Image?.data?.attributes?.name} cover`}
              className="md:hidden w-full h-[200px] object-cover object-center rounded-lg"
            />
          )}
          <div className="flex flex-col lg:flex-row gap-4 mb-6">
            <Button
              onClick={() => handleApplyNowClick()}
              variant="outline"
              className="bg-primaryA2 border-none text-white text-base py-5 lg:py-6 md:self-center md:w-[20rem] lg:w-full hover:bg-primaryA2 hover:text-white"
            >
              Apply Now
            </Button>
            <Button
              onClick={() => handleCheckEligibilityClick()}
              variant="outline"
              className="bg-neutral700 border-none text-white text-base py-5 lg:py-6 md:self-center md:w-[20rem] lg:w-full hover:bg-neutral700 hover:text-white"
            >
              Check Eligibility
            </Button>
          </div>
        </div>

        <div className="hidden lg:flex justify-center w-fit">
          {becomeADealer && (
            <Image
              transform="f-webp"
              src={
                becomeADealer?.data[0]?.attributes?.Image?.data?.attributes?.url
              }
              alt={`${becomeADealer?.data[0]?.attributes?.Image?.data?.attributes?.name} cover`}
              className="w-[40rem] h-[20rem] object-cover object-center rounded-lg"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default JoinTheMobility;
