import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  store: JSON.parse(localStorage.getItem("current_store_dmx") || "null"),
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload;
      localStorage.setItem("current_store_dmx", JSON.stringify(action.payload));
    },
  },
});

export const { setStore } = storeSlice.actions;

export default storeSlice.reducer;
