import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface IProps {
  locationData: any;
  handleSelectValueChange: (value: string) => void;
}

const SelectCity = ({ locationData, handleSelectValueChange }: IProps) => {
  return (
    <>
      <Select onValueChange={(value) => handleSelectValueChange(value)}>
        <SelectTrigger className="max-w-[10rem] text-base data-[placeholder]:text-primaryA1 text-primaryA1 bg-white focus-visible:ring-0 focus-visible:ring-offset-0">
          <SelectValue placeholder="Select City" />
        </SelectTrigger>
        <SelectContent
          ref={(ref) => {
            if (!ref) return;
            ref.ontouchstart = (e) => e.preventDefault();
          }}
        >
          {locationData?.map((option: { location: string }, index: number) => (
            <SelectItem
              key={index}
              className="focus:bg-neutral30 text-black"
              value={option.location}
            >
              {option.location}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default SelectCity;
