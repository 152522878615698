import Facebook from "../../assets/svg/Footer/Facebook.svg";
import Linkedin from "../../assets/svg/Footer/Linkedin.svg";
import Youtube from "../../assets/svg/Footer/Youtube.svg";
import Instagram from "../../assets/svg/Footer/Instagram.svg";
import {
  ArrowsCounterClockwiseIcon,
  CertifiedIcon,
  CheckPointsIcon,
  ClockIcon,
  Documentation,
  LightningIcon,
  PriceNote,
  SearchIcon,
  ShieldPlusIcon,
  SparkleIcon,
  StopWatchIcon,
  ThumbsUpIcon,
  WrenchIcon,
} from "@/assets/Icons";
import { CAREERS_URL, IMAGE_KIT_BASE_URL } from "@/utils/constants";

interface valuePropositionDataType {
  quantity: string;
  type: string;
  icon: React.ReactNode;
}

export const valuePropositionData: valuePropositionDataType[] = [
  {
    quantity: "100%",
    type: "Refurbished",
    icon: (
      <SparkleIcon
        size={24}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="hero_paint_sparkle"
      />
    ),
  },
  {
    quantity: "1 Year",
    type: "Warranty",
    icon: <ClockIcon size={24} oneStopColor="#fff" twoStopColor="#fff" />,
  },
  {
    quantity: "3 Free",
    type: "Services",
    icon: (
      <ArrowsCounterClockwiseIcon
        size={24}
        oneStopColor="#fff"
        twoStopColor="#fff"
      />
    ),
  },
];

interface brandType {
  name: string;
  availability?: string;
  img: unknown;
}

export const brandData: brandType[] = [
  { name: "Hero", img: `${IMAGE_KIT_BASE_URL}/vehicle_icons/Hero.jpg` },
  { name: "TVS", img: `${IMAGE_KIT_BASE_URL}/vehicle_icons/TVS.jpg` },
  {
    name: "Bajaj",
    img: `${IMAGE_KIT_BASE_URL}/vehicle_icons/Bajaj.jpg`,
  },
  {
    name: "Yamaha",
    img: `${IMAGE_KIT_BASE_URL}/vehicle_icons/Yamaha.jpg`,
  },
  {
    name: "KTM",
    img: `${IMAGE_KIT_BASE_URL}/vehicle_icons/KTM.jpg`,
  },
  {
    name: "Honda",
    img: `${IMAGE_KIT_BASE_URL}/vehicle_icons/Honda.jpg`,
  },
];

export const premiumRenewedData = [
  {
    icon: <CheckPointsIcon width={30} />,
    heading: "100+ Quality Checkpoints",
    description:
      "Every vehicle goes through rigorous testing for quality before certification",
  },
  {
    icon: <ShieldPlusIcon />,
    heading: "1-Year Warranty Assured",
    description:
      "Ensuring peace and trust in every ride with a assured warranty for a year",
  },
  {
    icon: <CertifiedIcon width={30} />,
    heading: "Certified by DriveX Experts",
    description:
      "Quality is our code of conduct. Each two-wheeler is tested and certified for quality by seasoned experts",
  },
  {
    icon: <SparkleIcon size={30} />,
    heading: "As Good As New",
    description:
      "Spot the difference? It’s not easy! As each vehicle looks, feels and rides just like new!",
  },
];

export interface budgetType {
  comparison: string;
  budget: string;
  value: string;
}

export const budgetData: budgetType[] = [
  { comparison: "Less than", budget: "₹50,000", value: "50000" },
  { comparison: "Less than", budget: "₹75,000", value: "75000" },
  { comparison: "Less than", budget: "₹1,00,000", value: "100000" },
  { comparison: "Less than", budget: "₹1,25,000", value: "125000" },
  { comparison: "Less than", budget: "₹1,50,000", value: "150000" },
  { comparison: "Less than", budget: "₹2,00,000", value: "200000" },
];

interface emiPlanType {
  img: unknown;
  background_color: string;
}

export const emiPlan: emiPlanType[] = [
  {
    img: `${IMAGE_KIT_BASE_URL}/ik_homepage/TVS_Credit.png`,
    background_color: "bg-white",
  },
  {
    img: `${IMAGE_KIT_BASE_URL}/ik_homepage/IDFC_First_Bank.png`,
    background_color: "bg-[#9C1D26]",
  },
];

interface BuyCTAStepsType {
  index: string;
  heading: string;
  description: string;
}

export const BuyCTASteps: BuyCTAStepsType[] = [
  {
    index: "01",
    heading: "Endless Choices",
    description: "Vast range of options in all categories",
  },
  {
    index: "02",
    heading: "Test It. Feel It.",
    description: "Test ride to feel the quality before you buy",
  },
  {
    index: "03",
    heading: "Buy In a Blink",
    description: "Buy without the stress of documentation",
  },
  {
    index: "04",
    heading: "As Good As New",
    description: "Rides that are just as good as new",
  },
];

export const QualityServiceSteps = [
  {
    index: <LightningIcon />,
    heading: "Fast Track Service",
    description: "Eliminate the waiting period with our lightning fast service",
  },
  {
    index: <ThumbsUpIcon />,
    heading: "Reliable",
    description: "Serviced with care for peak performance and safe rides.",
  },
  {
    index: <PriceNote size={37} />,
    heading: "Economical",
    description: "Expert service made affordable for every two-wheeler",
  },
  {
    index: <WrenchIcon size={32} />,
    heading: "Expert Technicians",
    description:
      "Highly trained technicians ensuring a quality service everytime.",
  },
];

interface SellingBenefitsType {
  icon: React.ReactNode;
  heading: string;
  description: string;
}

export const SellingBenefits: SellingBenefitsType[] = [
  {
    icon: <PriceNote size={37} />,
    heading: "Best Price Guaranteed",
    description:
      "Get the best value for your two-wheeler with our expert evaluation",
  },
  {
    icon: <Documentation size={30} />,
    heading: "Hassle-free Documentation",
    description: "Leave the hassle of paperwork to us and sell stress-free",
  },
  {
    icon: <StopWatchIcon size={30} />,
    heading: "Fast and Secure Payment",
    description:
      "Get instant payment on your account made through secure sources",
  },
  {
    icon: (
      <SearchIcon
        size={30}
        oneStopColor="#E63C32"
        twoStopColor="#F47823"
        fill="#E63C32"
      />
    ),
    heading: "Free Doorstep Evaluation",
    description:
      "Free doorstep evaluation for your two-wheeler on selling with us",
  },
];

interface SocialMediaImagesType {
  img: unknown;
  link: string;
  alt: string;
}

export const SocialMediaImages: SocialMediaImagesType[] = [
  {
    img: Facebook,
    link: "https://www.facebook.com/thedrivex",
    alt: "Facebook Icon",
  },
  {
    img: Linkedin,
    link: "https://www.linkedin.com/company/the-drivex/",
    alt: "LinkedIn Icon",
  },
  {
    img: Youtube,
    link: "https://www.youtube.com/@DriveX01",
    alt: "YouTube Icon",
  },
  {
    img: Instagram,
    link: "https://www.instagram.com/thedrivex/",
    alt: "Instagram Icon",
  },
];

interface LinkType {
  text: string;
  url?: string;
}

export const QuickLinks: LinkType[] = [
  {
    text: "Buy",
    url: "/buy-two-wheelers",
  },
  {
    text: "Sell",
    url: "/sell-two-wheelers",
  },
  {
    text: "Service",
    url: "/service",
  },
  // { text: "Exchange Bike", url: "" },
  // { text: "DriveX Partners", url: "" },
  { text: "About Us", url: "/about-us" },
  // { text: "FAQs", url: "" },
  { text: "Blogs", url: "/blogs" },
  // { text: "Media", url: "" },
  {
    text: "Careers",
    url: CAREERS_URL,
  },
  // { text: "Refunds", url: "" },
  {
    text: "Privacy Policy",
    url: "/privacy-policy",
  },
  { text: "Terms of Service", url: "/terms" },
];

export const PopularSearches: LinkType[] = [
  { text: "TVS " },
  { text: "Bajaj" },
  { text: "Hero" },
  { text: "Honda" },
  { text: "Yamaha" },
  { text: "Suzuki" },
  { text: "Royal Enfield" },
  { text: "TVS Jupiter" },
  { text: "TVS Apache" },
  { text: "Honda Dio" },
  { text: "Yamaha Ray" },
];
