import Image from "@/components/ui/image.tsx";
import { Dialog } from "@/components/ui/dialog";
import ImageViewer from "@/pages/ProductDetail/ImageViewer";
import CarouselSection from "@/pages/ProductDetail/CarouselSection";
import ProductInfo from "@/pages/ProductDetail/ProductInfo";
import { cn } from "@/lib/utils";
import { trackImageViewerOpened } from "@/pages/ProductDetail/data";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CAROUSEL_IMAGE_UPDATE_INTERVAL } from "@/utils/constants";
import TopNavigation from "@/pages/ProductDetail/TopNavigation";
import ProductCardSkeletonLoader from "@/components/SkeletonLoaders/ProductCardSkeletonLoader";
import CarouselImagePreview from "@/pages/ProductDetail/CarouselImagePreview";
import { Button } from "@/components/ui/button";
import { ChevronLeft as LeftIcon } from "lucide-react";
import Sold from "./Sold";
import SoldSuccess from "./Sold/SoldSuccess";
import Verify from "./Sold/Verify";
import {
  useGetPreSignedURLMutation,
  useGetStoreVehicleQuery,
  useGetVehicleOrderDetailsQuery,
  useUpdateOrdersMutation,
} from "@/store/services/Dmx/storeVehicles";
import { formFields } from "./data";
import EditVehicleDetailsModal from "@/components/EditVehicleDetails/EditVehicleDetailsModal";
import { containsOnlyDigits } from "@/utils/numerics";
import DmxVehicleInfo from "./DmxVehicleInfo";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import UpdateVehicleDetail from "./UpdateVehicleDetail";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { handleShare } from "@/utils/share";
import { track } from "@/utils/mixpanel/actions";
import {
  DX_CUSTOMER_DETAILS_SUBMITTED,
  DX_DOCUMENT_DOWNLOADED,
  DX_DOCUMENT_SHARED,
  DX_PDP_VIEW,
} from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import Navbar from "@/components/Navbar";
import { isValidEmail } from "@/utils/validation";

const DmxStoreDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openCarouselImgPreview, setOpenCarouselImgPreview] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [storeOrderDetails, setStoreOrderDetails] = useState(undefined);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [otp, setOtp] = useState("");
  const [openQRModal, setOpenQRModal] = useState(false);
  const [openEditVehicleModal, setOpenEditVehicleModal] = useState(false);
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useScreenDetector();
  const params = useParams();
  const sold = searchParams.get("sold") || "";
  const vehicleId = params.vehicleId;
  const {
    refetch: refetchGetStoreVehicle,
    data: product = {},
    isError,
  } = useGetStoreVehicleQuery({
    vehicleId,
  });
  const { refetch: refetchGetVehicleOrderDetail, data: order = {} } =
    useGetVehicleOrderDetailsQuery({
      vehicle_id: vehicleId,
    });
  const [getPreSignedUrl] = useGetPreSignedURLMutation();
  const [updateOrder] = useUpdateOrdersMutation();
  const productDetail = product.data;
  const isLoading = !productDetail && !isError;
  const isVehicleAvailable =
    productDetail?.availabilityStatus !== "unavailable";
  const store = useSelector((state: any) => state.store.store);
  const user = useSelector((state: any) => state.user.currentUser);
  const orderDetails = order?.data?.order;
  const role = localStorage.getItem("role");
  const commonEventProperties = {
    user_id: user?.id,
    role: role,
    vehicle_name: productDetail?.vehicleName,
    vehicle_id: productDetail?.vehicleId,
    purchase_price: productDetail?.purchase_price?.displayValue,
    sale_price: productDetail?.price?.displayValue,
    km_driven: productDetail?.kmDriven?.displayValue,
    registration_number: productDetail?.registrationNumber,
    ownership: productDetail?.ownership,
    store_name: productDetail?.storeName,
    manufacturing_year: productDetail?.manufactureYear,
    customer_name: formData?.customer_name,
    customer_phone_number: formData?.customer_phone_number,
    customer_email_id: formData?.customer_email,
  };

  const isVehicleAssured = productDetail?.drivexAssured
    ? "Assured"
    : "Self_Procured";

  useEffect(() => {
    if (productDetail) {
      track(DX_PDP_VIEW, {
        role: role,
        user_id: user?.id,
        vehicle_name: productDetail?.vehicleName,
        vehicle_id: productDetail?.vehicleId,
        purchase_price: productDetail?.purchase_price?.displayValue,
        sale_price: productDetail?.price?.displayValue,
        km_driven: productDetail?.kmDriven?.displayValue,
        registration_number: productDetail?.registrationNumber,
        ownership: productDetail?.ownership,
        manufacturing_year: productDetail?.manufactureYear,
        inventory_type: isVehicleAssured,
        store_name: productDetail?.storeName,
      });
    }
  }, [productDetail]);

  useEffect(() => {
    setFormData({
      ...formData,
      selling_price: productDetail?.price?.displayValue,
    });
  }, [productDetail]);

  useEffect(() => {
    const fetchUpdatedOrder = async () => {
      const payload = {
        user_id: parseInt(user?.id),
        vehicle_id: parseInt(vehicleId || ""),
        store_id: parseInt(store?.store_id),
        sales_price: parseInt(formData.selling_price.replace(/,/g, "")),
        customer_pan: formData.customer_pan,
        customer_aadhaar: formData.customer_aadhaar,
        customer_name: formData.customer_name,
        customer_email_id: formData.customer_email,
        customer_address: formData.customer_address,
        payments: [
          {
            proof_of_payment: `${productDetail?.registrationNumber}.${formData["proof_of_payment"]?.type?.split("/")?.[1]}`,
            payment_reference_id: formData.payment_reference_id,
            mode_of_payment: formData?.mode_of_payment?.toUpperCase(),
            amount: parseInt(formData.selling_price.replace(/,/g, "")),
          },
        ],
        customer_phone_number: formData.customer_phone_number,
      };

      if (containsOnlyDigits(formData.customer_pan_or_aadhaar)) {
        payload.customer_aadhaar = formData.customer_pan_or_aadhaar;
      } else {
        payload.customer_pan = formData.customer_pan_or_aadhaar;
      }

      const data: any = await updateOrder({
        store_id: store?.store_id,
        data: payload,
      });

      if (data) {
        setStoreOrderDetails(data?.data?.data?.order);
      }
    };

    if (sold === "success" && formData?.customer_name) {
      fetchUpdatedOrder();
    }
  }, [sold]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex =
        (currentImageIndex + 1) % productDetail?.imagePaths?.length;

      setCurrentImageIndex(nextIndex);
    }, CAROUSEL_IMAGE_UPDATE_INTERVAL);

    return () => clearInterval(intervalId);
  }, [currentImageIndex, productDetail?.imagePaths?.length]);

  const invokeS3Url = async (s3Url: string, method: string, data?: any) => {
    try {
      const response = await fetch(s3Url, {
        method: method,
        body: data,
      });

      return response;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
    }
  };

  const handleInputChange = async (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLInputElement
    >,
  ) => {
    const { name, value } = e.target;
    const files = (e.target as HTMLInputElement)?.files;

    if (name === "customer_phone_number" && value.length > 10) return;

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }

    if (files) {
      setFormData((prevData: any) => ({ ...prevData, [name]: files[0] }));

      const data: any = await getPreSignedUrl({
        data: {
          file_names: [
            `${productDetail?.registrationNumber}.${files[0].type.split("/")[1]}`,
          ],
          doc_type: "proof_of_payment",
          meta_data: "",
        },
      });

      if (data) {
        const s3Url = data?.data?.data[0].url;
        if (s3Url) {
          const mediaData = new FormData();
          mediaData.append("file", files[0]);

          await invokeS3Url(s3Url, "PUT", mediaData);
        }
      }
    } else {
      if (name === "mode_of_payment" && value === "cash_on_delivery") {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const { proof_of_payment, ...updatedFormData } = formData;
        setFormData({ ...updatedFormData, [name]: value });
      } else {
        setFormData((prevData: any) => ({ ...prevData, [name]: value }));
      }
    }
  };

  const displaySoldState = () => {
    switch (sold) {
      case "detail":
        return (
          <Sold
            formData={formData}
            errors={errors}
            handleInputChange={handleInputChange}
            partnerType={store?.partner_type}
          />
        );
      case "verify":
        return (
          <Verify
            formData={formData}
            otp={otp}
            setOtp={setOtp}
            modelName={productDetail?.modelName}
            commonEventProperties={commonEventProperties}
          />
        );
      case "success":
        return (
          <SoldSuccess
            orderDetails={orderDetails ?? storeOrderDetails}
            handleDownload={handleDownload}
          />
        );
      default:
        return null;
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: boolean } = {};
    formFields.forEach((field) => {
      if (field.required && !formData[field.name]) {
        if (
          field.name === "proof_of_payment" &&
          formData["mode_of_payment"] === "cash_on_delivery"
        ) {
          return;
        }
        newErrors[field.name] = true;
      }

      // Add email validation for customer_email
      if (field.name === "customer_email" && formData[field.name]) {
        if (!isValidEmail(formData[field.name])) {
          newErrors[field.name] = true;
        }
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextClick = () => {
    if (validateFields()) {
      setSearchParams(
        {
          sold: "verify",
          redirect: "?sold=success",
        },
        {
          preventScrollReset: true,
          replace: true,
        },
      );
    }

    track(DX_CUSTOMER_DETAILS_SUBMITTED, commonEventProperties);
  };

  const handleDownload = async (
    type?: string,
    share = false,
    shareMessage?: string,
  ) => {
    const events = {
      user_id: user?.id,
      role: role,
      vehicle_name: productDetail?.vehicleName,
      vehicle_id: productDetail?.vehicleId,
      store_name: productDetail?.storeName,
      type: type,
    };
    const fileName = `${orderDetails.registrationNumber}.pdf`;
    const data: any = await getPreSignedUrl({
      data: {
        file_names: [`${fileName}`],
        doc_type: type,
        prefix: "",
        operation: "get",
      },
    });

    if (data) {
      const s3Url = data?.data?.data[0].url;

      if (s3Url) {
        const file = await invokeS3Url(s3Url, "GET");

        if (share) {
          track(DX_DOCUMENT_SHARED, events);
          handleShare({
            text: shareMessage,
            url: s3Url,
          });
          return;
        }

        track(DX_DOCUMENT_DOWNLOADED, events);
        downloadToDevice(file, fileName);
      }
    }
  };

  const downloadToDevice = async (file: any, fileName: string) => {
    const blob = await file?.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      {!isMobile && (
        <Navbar
          backgroundColor="bg-white"
          showLocation={false}
          showStores={true}
          showDmxLogo={true}
          showRecentSearches={false}
          showPopularSearches={false}
          showPopularBrands={false}
          showLinks={false}
          disableStore={true}
          showSearchbar={false}
        />
      )}
      <div
        className={cn(
          "max-w-7xl w-full mx-auto mb-24",
          !isMobile && "pt-[129px]",
          isDesktop && "pt-[80px]",
        )}
      >
        <Dialog open={openCarouselImgPreview}>
          <CarouselImagePreview
            isVehicleAvailable={isVehicleAvailable}
            productDetail={productDetail}
            setOpenCarouselImgPreview={setOpenCarouselImgPreview}
            showWishList={false}
            showShare={false}
            showTestRide={false}
          />
        </Dialog>

        {isLoading && <ProductCardSkeletonLoader />}

        {isDesktop && productDetail && (
          <div className="text-neutral-900 mx-4 my-6">
            <span className="text-primaryA1">{store?.store_name}</span> /{" "}
            {productDetail?.vehicleName}
          </div>
        )}
        {productDetail && (
          <div className="flex flex-col lg:flex-row gap-4 relative">
            <div className="flex flex-col justify-end md:flex-row-reverse md:h-[400px]">
              <span className="w-full relative md:w-[628px]">
                <TopNavigation
                  isVehicleAvailable={isVehicleAvailable}
                  productDetail={productDetail}
                  showWishList={false}
                  showShare={false}
                />

                <Image
                  transform="q-10"
                  src={productDetail.imagePaths?.[currentImageIndex]}
                  alt={productDetail.vehicleName}
                  className={cn(
                    "w-full h-80 md:h-[400px] md:w-[628px] md:rounded-md ease-linear object-cover",
                    !isVehicleAvailable && "grayscale",
                  )}
                  onClick={() => {
                    setOpenImageViewer(true);
                    trackImageViewerOpened({
                      productDetail,
                      currentImageIndex,
                    });
                  }}
                />
                {openImageViewer && (
                  <Dialog open={openImageViewer}>
                    <ImageViewer
                      productDetail={productDetail}
                      imagePaths={productDetail.imagePaths}
                      setOpenImageViewer={setOpenImageViewer}
                      activeImage={currentImageIndex}
                    />
                  </Dialog>
                )}
              </span>

              <CarouselSection
                isVehicleAvailable={isVehicleAvailable}
                imagePaths={productDetail.imagePaths}
                setOpenCarouselImgPreview={setOpenCarouselImgPreview}
                productDetail={productDetail}
              />
            </div>

            <ProductInfo
              isVehicleAvailable={isVehicleAvailable}
              productInfo={productDetail}
              showEmi={false}
              showCallStore={false}
              showStoreName={false}
              showPrice={false}
              showDmxVehicleInfo={isDesktop && true}
              showTestRide={isDesktop && false}
              showUpdateVehicleDetail={isDesktop && true}
              setOpenEditVehicleModal={setOpenEditVehicleModal}
              openQRModal={openQRModal}
              setOpenQRModal={setOpenQRModal}
              handleDownload={handleDownload}
              orderDetails={orderDetails}
            />

            {!isDesktop && (
              <DmxVehicleInfo
                store={store}
                productDetail={productDetail}
                orderDetails={orderDetails}
                storeOrderDetails={storeOrderDetails}
                openQRModal={openQRModal}
                setOpenQRModal={setOpenQRModal}
                handleDownload={handleDownload}
              />
            )}
          </div>
        )}

        <Sheet
          open={sold !== ""}
          onOpenChange={() => {
            if (sold) {
              searchParams.delete("sold");
              setSearchParams(searchParams, {
                preventScrollReset: true,
                replace: true,
              });
            }
          }}
        >
          <SheetContent
            className={cn("max-w-full flex flex-col h-dvh p-2.5")}
            side={isMobile ? "bottom" : "right"}
          >
            <SheetHeader className="bg-white border-neutral50 border-b absolute top-0 right-0 left-0 w-full py-4 px-3 z-10 max-w-7xl mx-auto">
              <div className="flex gap-2">
                <SheetClose
                  onClick={() => {
                    if (sold === "detail") {
                      searchParams.delete("sold");
                      setSearchParams(searchParams, {
                        preventScrollReset: true,
                        replace: true,
                      });
                    } else if (sold === "verify") {
                      setSearchParams(
                        { sold: "detail" },
                        {
                          preventScrollReset: true,
                          replace: true,
                        },
                      );
                    } else if (sold === "success") {
                      navigate(`/dealer/vehicles/${vehicleId}`, {
                        preventScrollReset: true,
                        replace: true,
                      });
                    }
                  }}
                >
                  <LeftIcon />
                </SheetClose>
                <p>Vehicle Sale</p>
              </div>
            </SheetHeader>

            {displaySoldState()}

            <div className="bg-white border-t absolute bottom-0 right-0 left-0 w-full py-2 px-3 flex justify-between gap-2 items-center">
              {sold === "success" ? (
                <Button
                  onClick={() => {
                    refetchGetVehicleOrderDetail();
                    refetchGetStoreVehicle();
                    navigate(`/dealer/vehicles/${vehicleId}`, {
                      preventScrollReset: true,
                      replace: true,
                    });
                  }}
                  variant="outline"
                  className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
                >
                  Finish
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      searchParams.delete("sold");
                      setSearchParams(searchParams, {
                        preventScrollReset: true,
                        replace: true,
                      });
                    }}
                    variant="outline"
                    className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={() => {
                      if (sold === "detail") {
                        handleNextClick();
                      } else if (sold === "verify") {
                        setSearchParams(
                          { sold: "success" },
                          {
                            preventScrollReset: true,
                            replace: true,
                          },
                        );
                      }
                    }}
                    variant="outline"
                    disabled={sold === "verify" && otp.length < 4}
                    className={cn(
                      "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
                      sold === "verify" &&
                        otp.length < 4 &&
                        "bg-neutral50 text-neutral200",
                    )}
                  >
                    {sold === "detail" ? "Next" : "Verify"}
                  </Button>
                </>
              )}
            </div>
          </SheetContent>
        </Sheet>

        {productDetail && (
          <EditVehicleDetailsModal
            open={openEditVehicleModal}
            onClose={() => setOpenEditVehicleModal(false)}
            vehicleDetails={productDetail}
            refetchStoreVehicle={refetchGetStoreVehicle}
          />
        )}

        {!isDesktop && (
          <UpdateVehicleDetail
            productDetail={productDetail}
            setOpenEditVehicleModal={setOpenEditVehicleModal}
          />
        )}
      </div>
    </>
  );
};

export default DmxStoreDetail;
