import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Image from "@/components/ui/image";
import { useEffect, useState } from "react";
import { IVehicleOrder } from ".";
import { useScreenDetector } from "@/hooks/useScreenDetector";

const IncomingVehicleCard = ({
  data,
  setCurrent,
  setCount,
  handleRecievedVehicleClick,
}: {
  data: {
    imagePaths: string[];
    vehicleName: string;
    registrationNumber: string;
    kmDriven?: { displayValue: string };
    manufactureYear: string | number;
  }[];
  handleRecievedVehicleClick: (item: IVehicleOrder) => void;
  setCurrent: (current: number) => void;
  setCount: (count: number) => void;
}) => {
  const [api, setApi] = useState<CarouselApi>();
  const { isDesktop } = useScreenDetector();

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api, data]);

  return (
    <>
      <Carousel setApi={setApi}>
        {isDesktop && (
          <>
            <CarouselPrevious className="absolute -left-4 top-1/2 transform -translate-y-1/2 z-10 hover:bg-primaryA2 hover:text-white" />
            <CarouselNext className="absolute -right-4 top-1/2 transform -translate-y-1/2 z-10 hover:bg-primaryA2 hover:text-white" />
          </>
        )}
        <CarouselContent className="flex gap-3 p-4 select-none">
          {data?.map((item: any, index: number) => (
            <CarouselItem
              key={index}
              className="flex flex-col gap-4 basis-[99%] sm:basis-1/3 bg-white pl-0 p-4 rounded-lg shadow-md"
            >
              <div className="flex items-center gap-2">
                <Image
                  className="w-[5.8rem] h-[3.5rem] object-cover rounded-lg"
                  transform="f-webp"
                  src={item.imagePaths[0]}
                  alt={item.vehicleName}
                />
                <div className="flex flex-col gap-2">
                  <p className="text-lg font-medium text-[#2F2F2F] line-clamp-1 max-w-[16rem] text-wrap">
                    {item.vehicleName}
                  </p>
                  <p className="text-neutral300 text-xs">
                    {item.registrationNumber} • {item.kmDriven?.displayValue}{" "}
                    KMs • {item?.manufactureYear}
                  </p>
                </div>
              </div>

              <div className="w-full">
                <Button
                  variant="outline"
                  className="bg-primaryA2 border-none text-white w-full hover:bg-primaryA2 hover:text-white"
                  onClick={() => handleRecievedVehicleClick(item)}
                >
                  Received
                </Button>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </>
  );
};

export default IncomingVehicleCard;
