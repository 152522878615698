import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { formFields } from "../data";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { CheckCircle, EditIcon } from "@/assets/Icons";

type Props = {
  formData: any;
  handleInputChange: any;
  errors: any;
  partnerType: "fofo" | "coco";
};

const Sold = ({ formData, handleInputChange, errors, partnerType }: Props) => {
  return (
    <>
      <div className="flex flex-col gap-5 my-[70px] overflow-auto">
        {formFields.map((field) => {
          if (
            field.name === "proof_of_payment" &&
            field?.hide &&
            formData[field?.hide?.field] === field?.hide?.value
          ) {
            return null;
          } else {
            return (
              <div key={field.name} className="flex flex-col gap-2">
                <div className="flex gap-0.5">
                  <label htmlFor={field.name} className="text-sm font-semibold">
                    {field.label}
                  </label>
                  {field.required && <p className="text-red-600">*</p>}
                </div>
                {field.type === "textarea" ? (
                  <Textarea
                    className={cn(
                      "bg-neutral30 h-24 mb-2",
                      errors[field.name] && "bg-red-100",
                    )}
                    name={field.name}
                    placeholder={field.placeholder}
                    maxLength={field.maxLength}
                    value={formData[field.name as keyof typeof formData] || ""}
                    onChange={handleInputChange}
                  />
                ) : field.type === "select" ? (
                  <Select
                    onValueChange={(value) =>
                      handleInputChange &&
                      handleInputChange({ target: { name: field.name, value } })
                    }
                    value={formData[field.name]}
                  >
                    <SelectTrigger
                      className={cn(
                        "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                        errors[field.name] && "bg-red-100",
                      )}
                      name={field.name}
                      value={
                        formData[field.name as keyof typeof formData] || ""
                      }
                      onChange={handleInputChange}
                    >
                      <SelectValue placeholder="Select a Mode" />
                    </SelectTrigger>
                    <SelectContent
                      ref={(ref) => {
                        if (!ref) return;
                        ref.ontouchstart = (e) => e.preventDefault();
                      }}
                    >
                      {field.options?.map((option: any) => (
                        <SelectItem
                          key={option.value}
                          className="focus:bg-neutral30"
                          value={option.value}
                        >
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                ) : field.type === "file" ? (
                  <>
                    <div
                      className={cn(
                        "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 cursor-pointer",
                        errors[field.name] && "bg-red-100",
                      )}
                      onClick={() =>
                        document
                          .getElementById(`file-input-${field.name}`)
                          ?.click()
                      }
                    >
                      {formData[field.name]?.name ? (
                        <div className="flex gap-2 items-center px-2 justify-between">
                          {" "}
                          <div className="flex gap-2">
                            <CheckCircle />{" "}
                            <span className="text-sm">
                              {formData[field.name]?.name}
                            </span>
                          </div>
                          <EditIcon size={20} fill="#D1372E" />
                        </div>
                      ) : (
                        field.placeholder
                      )}
                    </div>
                    <input
                      id={`file-input-${field.name}`}
                      className="hidden"
                      name={field.name}
                      type="file"
                      onChange={handleInputChange}
                    />
                  </>
                ) : (
                  <div
                    className={cn(
                      "flex items-center px-4 rounded-md bg-neutral30 border-none",
                      errors[field.name] && "bg-red-100",
                      !field?.prefill && "none p-0",
                    )}
                  >
                    {field?.prefill && (
                      <span className="text-sm">{field.prefill}</span>
                    )}
                    <Input
                      className={cn(
                        "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                        errors[field.name] && "bg-red-100",
                      )}
                      name={field.name}
                      placeholder={field.placeholder}
                      type={field.type}
                      required={field.required}
                      value={
                        formData[field.name as keyof typeof formData] || ""
                      }
                      onChange={handleInputChange}
                      disabled={partnerType === field.disabled}
                      maxLength={field.maxLength}
                    />
                  </div>
                )}
                {field.description && (
                  <p className="text-neutral300 text-sm">{field.description}</p>
                )}
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

export default Sold;
