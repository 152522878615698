import {
  DownloadIcon,
  EditIcon,
  ShareIcon,
  // SingleDocumentIcon,
} from "@/assets/Icons";
import PendingMessage from "../PendingMessage";
import { cn } from "@/lib/utils";
import { useScreenDetector } from "@/hooks/useScreenDetector";

const CustomerDetail = ({
  orderDetails,
  showDocuments = true,
  showPendingMessage = false,
  handleDownload,
}: any) => {
  const { isDesktop } = useScreenDetector();

  return (
    <>
      <div className="flex flex-col gap-4 border-b py-4">
        <div className="flex">
          <p className="text-neutral300 w-1/2">Customer Name</p>
          <p className="w-1/2 text-right">{orderDetails?.customer_name}</p>
        </div>

        <div className="flex">
          <p className="text-neutral300 w-1/2">Phone Number</p>
          <p className="w-1/2 text-right">
            +91 {orderDetails?.customer_phone_number}
          </p>
        </div>

        <div className="flex">
          <p className="text-neutral300 w-1/2">Email ID</p>
          <p className="w-1/2 text-right">{orderDetails?.customer_email_id}</p>
        </div>

        <div className="flex flex-col">
          <p className="text-neutral300 w-1/2">Address</p>
          <p className="">{orderDetails?.address}</p>
        </div>
      </div>

      {showDocuments && (
        <div className="py-4 flex flex-col gap-6">
          {/* This will be uncommented in the future, once finance team gives the approval */}

          {/* <div className="flex justify-between">
            <div className="flex gap-3">
              <div className="flex-center bg-violet50 rounded-md p-2">
                <SingleDocumentIcon size={16} />
              </div>
              <div>
                <p className="text-sm">Invoice</p>
                <p className="text-xs text-neutral200">243 kb</p>
              </div>
            </div>

            <div className="flex gap-2">
              <div
                className="flex-center bg-neutral30 rounded-md p-2"
                onClick={() => handleDownload("invoice")}
              >
                <DownloadIcon size={16} />
              </div>

              <div
                className="flex-center bg-neutral30 rounded-md p-2"
                onClick={() =>
                  handleDownload(
                    "invoice",
                    true,
                    `Congrats! The ${orderDetails?.vehicleName} is officially yours. Here is your purchase invoice:`,
                  )
                }
              >
                <ShareIcon size={16} oneStopColor="#000" twoStopColor="#000" />
              </div>
            </div>
          </div> */}

          <div className="flex justify-between">
            <div className="flex gap-3">
              <div className="flex-center bg-violet50 rounded-md p-2">
                <EditIcon size={16} />
              </div>
              <div>
                <p className="text-sm">Delivery Note</p>
                <p className="text-xs text-neutral300">75 kb</p>
              </div>
            </div>

            <div className="flex gap-2">
              <div
                className="flex-center bg-neutral30 rounded-md p-2"
                onClick={() => handleDownload("delivery_note")}
              >
                <DownloadIcon size={16} />
              </div>

              <div
                className="flex-center bg-neutral30 rounded-md p-2"
                onClick={() =>
                  handleDownload(
                    "delivery_note",
                    true,
                    `Congrats! The ${orderDetails?.vehicleName} is officially yours. Here is your delivery note:`,
                  )
                }
              >
                <ShareIcon size={16} oneStopColor="#000" twoStopColor="#000" />
              </div>
            </div>
          </div>
        </div>
      )}

      {showPendingMessage && (
        <PendingMessage className={cn("py-4 my-4", isDesktop && "mb-0")} />
      )}
    </>
  );
};

export default CustomerDetail;
