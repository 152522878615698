import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = import.meta.env.VITE_HOME_URL;

export const dmxApi = createApi({
  reducerPath: "dmxApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getStoreVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/vehicles/`,
        };
      },
    }),
    getStoreVehicle: builder.query({
      query: ({ vehicleId }) => {
        return {
          url: `/hawk/api/vehicles/${vehicleId}`,
        };
      },
    }),
    getStores: builder.query({
      query: () => {
        return {
          url: `/hawk/api/stores/`,
        };
      },
    }),
    getIncomingVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/incoming-vehicles/`,
        };
      },
    }),
    updateVehicleDetails: builder.mutation({
      query: ({ vehicle_id, data }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getPreSignedURL: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/hawk/api/media/pre-signed-url/`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateOrders: builder.mutation({
      query: ({ store_id, data }) => {
        return {
          url: `/hawk/api/stores/${store_id}/orders/`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSoldVehicles: builder.query({
      query: ({ store_id }) => {
        return {
          url: `/hawk/api/stores/${store_id}/orders/`,
        };
      },
    }),
    getVehicleOrderDetails: builder.query({
      query: ({ vehicle_id }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/orders/`,
          method: "GET",
        };
      },
    }),
    editVehicleDetails: builder.mutation({
      query: ({ vehicle_id, payload }) => {
        return {
          url: `/hawk/api/vehicles/${vehicle_id}/`,
          method: "PUT",
          body: payload,
        };
      },
    }),
  }),
});

export const {
  useGetStoreVehiclesQuery,
  useGetStoresQuery,
  useGetStoreVehicleQuery,
  useGetIncomingVehiclesQuery,
  useUpdateVehicleDetailsMutation,
  useGetPreSignedURLMutation,
  useUpdateOrdersMutation,
  useGetVehicleOrderDetailsQuery,
  useEditVehicleDetailsMutation,
  useGetSoldVehiclesQuery,
} = dmxApi;
