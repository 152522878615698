import { lazy } from "react";

const Home = lazy(() => import("@/pages/Home"));
import ProductDetail from "@/pages/ProductDetail";
import Sell from "@/pages/Sell";
import ScheduleInspection from "@/pages/Sell/ScheduleInspection";
import TestRide from "@/pages/TestRide";
import TestRideRequested from "@/pages/TestRideRequested";
import Blogs from "@/pages/Blogs";
import Auth from "@/pages/Auth";
import InitializeConfigs from "../components/InitializeConfigs";
import {
  Outlet,
  createBrowserRouter,
  ScrollRestoration,
} from "react-router-dom";
import ProductListing from "@/pages/ProductListing";
import InspectionScheduled from "@/pages/InspectionScheduled";
import ManualSellFlow from "@/pages/Sell/ManualSellFlow";
import Wishlist from "@/pages/Wishlist";
import Profile from "@/pages/Profile";
import PrivateRoutes from "@/components/PrivateRoutes";
import { PrivacyPolicy, Terms } from "@/pages/Static";
import ErrorPage from "@/pages/Home/Error";
import BlogDetails from "@/pages/BlogDetail";
import ContactUs from "@/pages/ContactUs";
import RedirectionHandler from "@/hooks/useRedirection";
import StoreDetail from "@/pages/StoreDetail";
import EstimatedPrice from "@/pages/EstimatedPrice";
import Services from "@/pages/Services";
import ServiceFlow from "@/pages/Services/ServiceFlow";
import AboutUs from "@/pages/AboutUs";
import ServiceRequestedPage from "@/pages/Services/ServiceRequestedPage";
import StoreListing from "@/DmxPages/StoreListing";
import DmxStoreDetail from "@/DmxPages/StoreDetail";
import Dealer from "@/pages/Dealer";
import DealerProfile from "@/DmxPages/DealerProfile";

/**
 * Creates route objects for given element and paths.
 * @param element - The React element to render for the routes.
 * @param paths - An array of paths for which the element should be rendered.
 * @returns An array of route objects.
 */
const createRoute = (element: JSX.Element, paths: string[]) => {
  return paths.map((path) => ({ element, path }));
};

const getRoutesByRole = () => {
  const role = localStorage.getItem("role") || "website_user";
  let allowedPaths: string | any[] = [];

  switch (role) {
    case "website_user":
      allowedPaths = [
        {
          element: <Profile />,
          path: "/profile",
        },
        {
          element: <Wishlist />,
          path: "/wishlist",
        },
        {
          element: <EstimatedPrice />,
          path: "/sell-two-wheelers/estimated-price",
        },
        {
          element: <ScheduleInspection />,
          path: "/sell-two-wheelers/bike-inspection",
        },
        {
          element: <InspectionScheduled />,
          path: "/inspectionscheduled",
        },
        {
          element: <TestRide />,
          path: "/:city/buy-two-wheelers/:vehicleId/test-ride",
        },
        {
          element: <TestRideRequested />,
          path: "/:vehicleId/test-ride-requested",
        },
        {
          element: <ServiceRequestedPage />,
          path: "/service/service-requested",
        },
        {
          element: <Dealer />,
          path: "/become-a-dealer",
        },
      ];
      break;
    case "Dealer":
    case "SuperAdmin":
    case "SalesHead":
      allowedPaths = [
        ...createRoute(<StoreListing />, [
          "/dealer/sold-vehicles",
          "/dealer/vehicles",
        ]),
        {
          element: <DmxStoreDetail />,
          path: "/dealer/vehicles/:vehicleId",
        },
        {
          element: <DealerProfile />,
          path: "/dealer-profile",
        },
      ];
      break;
    default:
      // Optional: handle roles that don't match any case
      break;
  }

  return allowedPaths;
};

export const routes = createBrowserRouter([
  {
    element: (
      <>
        <RedirectionHandler />
        <ScrollRestoration />
        <InitializeConfigs />
        <Auth />
        <Outlet />
      </>
    ),
    errorElement: <ErrorPage onFallback={true} />,
    children: [
      {
        element: <Home />,
        path: "/",
      },
      {
        element: <Blogs />,
        path: "/blogs",
      },
      {
        element: <BlogDetails />,
        path: "/blogs/:slug",
      },
      {
        element: <ContactUs />,
        path: "/contactus",
      },
      {
        element: <AboutUs />,
        path: "/about-us",
      },
      {
        element: <ProductDetail />,
        path: "/:city/buy-two-wheelers/:vehicleId",
      },
      {
        element: <ProductListing />,
        path: "/:city/buy-two-wheelers",
      },
      {
        element: <StoreDetail />,
        path: "/:city/:store-slug",
      },
      {
        element: <ErrorPage onFallback={false} />,
        path: "*",
      },
      {
        element: <Sell />,
        path: "/sell-two-wheelers",
      },
      {
        element: <Terms />,
        path: "/terms",
      },
      {
        element: <PrivacyPolicy />,
        path: "/privacy-policy",
      },
      {
        element: <ManualSellFlow />,
        path: "/sell-two-wheelers/price-estimate",
      },
      {
        element: <Services />,
        path: "/service",
      },
      {
        element: <ServiceFlow />,
        path: "/service/book-service",
      },
      {
        element: <PrivateRoutes />,
        children: getRoutesByRole(),
      },
    ],
  },
]);
