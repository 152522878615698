import Location from "../Location/index.tsx";
import Nudge from "./Nudge.tsx";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { cn } from "@/lib/utils.ts";
import {
  DmxLogo,
  DownArrowIcon,
  DriveXLogo,
  HamburgerIcon,
  SearchIcon,
} from "@/assets/Icons.tsx";
import AnimatedLabel from "../AnimatedLabel/index.tsx";
import {
  PLACEHOLDER_TEXTS,
  PLACEHOLDER_TEXT_INTERVAL,
} from "@/utils/constants.ts";
import Search from "@/pages/Search/index.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { useSelector } from "react-redux";

interface IProps {
  handleHamburgerClick: () => void;
  handleLogoClick: () => void;
  handleSelectCity: () => void;
  handleSearchbarClick: () => void;
  handleStoreChange?: (value: string) => void;
  openLocation: boolean;
  setOpenLocation: React.Dispatch<React.SetStateAction<boolean>>;
  openSearch: boolean;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  showNudge: boolean;
  setShowNudge: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundColor: string;
  location: any;
  showLocation?: boolean;
  showSearchbar?: boolean;
  showStores?: boolean;
  storesData?: IStore[];
  showDmxLogo?: boolean;
  showRecentSearches: boolean;
  showPopularSearches: boolean;
  showPopularBrands: boolean;
  placeholder?: string;
  disableStore?: boolean;
}

const MobileNavbar = ({
  handleHamburgerClick,
  handleLogoClick,
  handleSearchbarClick,
  handleSelectCity,
  handleStoreChange,
  setOpenLocation,
  setOpenSearch,
  setShowNudge,
  backgroundColor,
  openLocation,
  openSearch,
  location,
  showNudge,
  showLocation,
  showSearchbar,
  showStores,
  storesData,
  showDmxLogo,
  showRecentSearches,
  showPopularSearches,
  showPopularBrands,
  placeholder,
  disableStore = false,
}: IProps) => {
  const isDealerPage = window.location.pathname.includes("dealer");
  const selectedDmxStore = useSelector((state: any) => state.store.store);
  return (
    <header className="">
      <div className={cn("flex-between", showSearchbar && "pb-4")}>
        <div className="flex gap-4 flex-row items-center">
          <span
            className="cursor-pointer"
            onClick={handleHamburgerClick}
            data-test-id="sidebar-button"
          >
            <HamburgerIcon />
          </span>
          <span
            data-test-id="navbar-drivex-logo"
            className="cursor-pointer"
            onClick={handleLogoClick}
          >
            {showDmxLogo ? <DmxLogo size={38} /> : <DriveXLogo size={130} />}
          </span>
        </div>

        {showLocation && (
          <div className="flex flex-col relative">
            <Dialog open={openLocation}>
              <DialogTrigger asChild>
                <Button
                  data-test-id="select-city"
                  onClick={handleSelectCity}
                  variant="outline"
                  className={cn(
                    "flex gap-3 border-none",
                    backgroundColor === "bg-neutral900"
                      ? "bg-neutral700"
                      : "bg-neutral30",
                  )}
                >
                  <span className="text-primaryA2">
                    {location || "Select city"}
                  </span>
                  <DownArrowIcon />
                </Button>
              </DialogTrigger>

              <Location setOpenLocation={setOpenLocation} />
            </Dialog>

            {showNudge && (
              <div className="absolute top-[50px] z-10 right-[-5px] w-72">
                <Nudge
                  setOpenLocation={setOpenLocation}
                  setShowNudge={setShowNudge}
                />
              </div>
            )}
          </div>
        )}

        {showStores && (
          <Select
            onValueChange={(value) =>
              handleStoreChange && handleStoreChange(value)
            }
            value={
              selectedDmxStore?.store_id ??
              (storesData?.[0]?.store_id as unknown as string)
            }
            disabled={disableStore}
          >
            <SelectTrigger
              className={cn(
                "flex gap-3 border-none relative max-w-[180px] text-primaryA1",
                backgroundColor === "bg-neutral900"
                  ? "bg-neutral700"
                  : "bg-neutral30",
              )}
            >
              <SelectValue placeholder="Select a store" />
            </SelectTrigger>
            <SelectContent
              ref={(ref) => {
                if (!ref) return;
                ref.ontouchstart = (e) => e.preventDefault();
              }}
            >
              {storesData?.map((store: any) => (
                <SelectItem
                  key={store.store_id}
                  className="focus:bg-neutral30"
                  value={store.store_id}
                >
                  {store.store_name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      </div>

      {openSearch && (
        <Search
          setOpenSearch={setOpenSearch}
          showRecentSearches={showRecentSearches}
          showPopularSearches={showPopularSearches}
          showPopularBrands={showPopularBrands}
          showSelectCity={isDealerPage ? false : true}
          showStores={isDealerPage ? true : false}
        />
      )}

      {showSearchbar && (
        <div
          data-test-id="Search-icon-input"
          onClick={handleSearchbarClick}
          className={cn(
            "flex items-center px-4 rounded-md bg-neutral30 border-none relative",
          )}
        >
          <SearchIcon />

          {!isDealerPage && (
            <AnimatedLabel
              texts={PLACEHOLDER_TEXTS}
              interval={PLACEHOLDER_TEXT_INTERVAL}
              label="Search for"
            />
          )}

          <Input
            className={"bg-neutral30 border-none"}
            placeholder={isDealerPage ? placeholder : undefined}
          />
        </div>
      )}
    </header>
  );
};

export default MobileNavbar;
