import { DriveXFilledIcon } from "@/assets/Icons";

type ProductLabelProps = {
  label?: string;
};

function ProductLabel({ label = "Assured" }: ProductLabelProps) {
  return (
    <div className="flex gap-2 items-center absolute top-3.5 p-2 py-1.5 bg-accent rounded-e-sm">
      <DriveXFilledIcon />
      <p className="text-white text-sm font-medium">{label}</p>
    </div>
  );
}

export default ProductLabel;
