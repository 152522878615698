import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Otp from "@/pages/Auth/Otp";
import { createCode } from "supertokens-web-js/recipe/passwordless";
import { DX_CUSTOMER_VERIFICATION_SUCCESSFUL } from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";

const Verify = ({
  formData,
  otp,
  setOtp,
  modelName,
  commonEventProperties,
}: any) => {
  const [userDetails, setUserDetails] = useState({
    phoneNumber: formData.customer_phone_number,
    name: formData.customer_name,
  });
  const [, setIsOtpView] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchParams] = useSearchParams();
  const sold = searchParams.get("sold") || "";

  useEffect(() => {
    if (userDetails?.phoneNumber !== "" && sold === "verify") {
      sendOTP();
      return;
    }
  }, []);

  async function sendOTP() {
    try {
      const response = await createCode({
        phoneNumber: `+91${userDetails.phoneNumber}`,
      });

      if (response.status === "OK") {
        setIsOtpView(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        if (err.message === "Phone number is invalid")
          setErrorMsg("Invalid number");
      } else {
        console.log("err", err);
      }
    }
  }

  return (
    <div className="flex flex-col gap-5 mt-[70px]">
      <Otp
        userDetails={userDetails}
        errorMsg={errorMsg}
        setErrorMsg={setErrorMsg}
        setIsOtpView={setIsOtpView}
        otp={otp}
        setOtp={setOtp}
        searchParams={searchParams}
        setUserDetails={setUserDetails}
        isOnlyOTPVerification={true}
        modelName={modelName}
        eventProperties={commonEventProperties}
        eventName={DX_CUSTOMER_VERIFICATION_SUCCESSFUL}
      />
    </div>
  );
};

export default Verify;
