import { UploadIcon } from "@/assets/Icons";

export const formFields = [
  {
    name: "selling_price",
    label: "Selling Price",
    type: "text",
    placeholder: "Enter Selling Price",
    required: true,
    description: "Final Sale Price(Includes GST)",
    disabled: "coco",
  },
  {
    name: "mode_of_payment",
    label: "Mode Of Payment",
    type: "select",
    placeholder: "Select Mode Of Payment",
    required: true,
    options: [
      { value: "cash_on_delivery", label: "Cash" },
      { value: "upi", label: "UPI" },
      { value: "finance", label: "Finance" },
      { value: "credit_card", label: "Credit Card" },
      { value: "bank_draft", label: "Bank Draft" },
      { value: "cheque", label: "Cheque" },
      { value: "wire_transfer", label: "Wire Transfer" },
    ],
  },
  {
    name: "proof_of_payment",
    label: "Proof Of Payment",
    type: "file",
    placeholder: (
      <div className="flex flex-col gap-1 flex-center">
        <UploadIcon />
        <p className="text-sm">Choose a file</p>
        <p className="text-xs text-[#A9ACB4]">
          PNG, JPEG and PDF formats, up to 5MB
        </p>
      </div>
    ) as unknown as string,
    required: true,
    hide: {
      field: "mode_of_payment",
      value: "cash_on_delivery",
    },
  },
  {
    name: "payment_reference_id",
    label: "Payment Reference ID",
    type: "text",
    placeholder: "Enter Payment Reference ID",
    required: false,
    maxLength: 20,
  },
  {
    name: "customer_name",
    label: "Customer Name",
    type: "text",
    placeholder: "Enter Customer Name",
    required: true,
    maxLength: 30,
  },
  {
    name: "customer_email",
    label: "Customer Email",
    type: "text",
    placeholder: "Enter Customer Email",
    required: true,
  },
  {
    name: "customer_pan_or_aadhaar",
    label: "Customer Aadhaar/PAN",
    type: "text",
    placeholder: "Enter Customer Aadhaar or PAN Number",
    required: true,
    maxLength: 12,
  },
  {
    name: "customer_address",
    label: "Customer Address",
    type: "textarea",
    placeholder: "Enter Customer Address",
    required: true,
    maxLength: 500,
  },
  {
    name: "customer_phone_number",
    label: "Customer Phone Number",
    type: "number",
    placeholder: "Enter Customer Phone Number",
    required: true,
    description: "An OTP will be sent to this number",
    prefill: "+91",
    maxLength: 10,
  },
];

export const editVehicleFormFields = [
  {
    name: "sellingPrice",
    label: "Selling Price",
    type: "number",
    placeholder: "Enter Selling Price",
    required: true,
    disabled: "coco",
  },
  {
    name: "kmDriven",
    label: "KMs Driven",
    type: "number",
    placeholder: "Enter KMs Driven",
    required: true,
  },
];
