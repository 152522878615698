import { ClockFilled } from "@/assets/Icons";
import { cn } from "@/lib/utils";

const PendingMessage = ({
  description = "Our representatives are validating all the details. You will be able to get the invoice and delivery note in a while.",
  className,
}: {
  description?: string;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        "px-2 py-4 flex gap-2 border rounded-md bg-violet50 border-violet100 flex-center",
        className,
      )}
    >
      <ClockFilled size={30} fill="#64459B" />
      <p className="text-sm text-violet500">{description}</p>
    </div>
  );
};

export default PendingMessage;
