import { InfoIcon } from "@/assets/Icons";
import { Button } from "@/components/ui/button";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { track } from "@/utils/mixpanel/actions";
import {
  DX_MARK_AS_SOLD,
  DX_PDP_EDIT_DETAILS_BUTTON_CLICKED,
} from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

interface IProps {
  productDetail: IVehicleInfo;
  className?: string;
  setOpenEditVehicleModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateVehicleDetail = ({
  productDetail,
  className,
  setOpenEditVehicleModal,
}: IProps) => {
  const [, setSearchParams] = useSearchParams();
  const { isDesktop } = useScreenDetector();
  const user = useSelector((state: any) => state.user.currentUser);
  const role = localStorage.getItem("role");

  const isVehicleAssured = productDetail?.drivexAssured
    ? "Assured"
    : "Self_Procured";

  const isVehicleAvailable = productDetail?.status === "available";

  const handleTracking = (eventName: string) => {
    track(eventName, {
      role: role,
      user_id: user?.id,
      vehicle_name: productDetail?.vehicleName,
      vehicle_id: productDetail?.vehicleId,
      purchase_price: productDetail?.purchase_price?.displayValue,
      sale_price: productDetail?.price?.displayValue,
      km_driven: productDetail?.kmDriven?.displayValue,
      registration_number: productDetail?.registrationNumber,
      manufacture_year: productDetail?.manufactureYear,
      ownership: productDetail?.ownerShip,
      store_id: productDetail?.storeId,
      inventory_type: isVehicleAssured,
      store_name: productDetail?.storeName,
    });
  };

  const handleEditDetails = () => {
    handleTracking(DX_PDP_EDIT_DETAILS_BUTTON_CLICKED);

    setOpenEditVehicleModal(true);
  };

  const handleMarkAsSold = () => {
    handleTracking(DX_MARK_AS_SOLD);

    setSearchParams(
      { sold: "detail" },
      {
        preventScrollReset: true,
        replace: true,
      },
    );
  };

  return (
    <div
      className={cn(
        "bg-white border-t fixed bottom-0 right-0 left-0 w-full py-2 px-3 flex flex-col gap-2 items-center",
        className,
        isDesktop && "border-none relative py-0",
      )}
    >
      {isVehicleAvailable && (
        <p className="flex items-center gap-2 text-neutral300 text-xs font-semibold">
          <InfoIcon size={18} /> Mark as Sold to generate Invoice and Delivery
          note
        </p>
      )}
      <div className="flex justify-between gap-2 w-full">
        <Button
          variant="outline"
          disabled={productDetail?.status === "reserved"}
          className={cn(
            "flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500",
            productDetail?.status === "reserved" &&
              "bg-neutral50 text-neutral200 border-none",
          )}
          onClick={() => handleEditDetails()}
        >
          Edit Details
        </Button>

        <Button
          variant="outline"
          className={cn(
            "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
            productDetail?.status === "reserved" &&
              "bg-neutral50 text-neutral200",
          )}
          disabled={productDetail?.status === "reserved"}
          onClick={() => handleMarkAsSold()}
        >
          Mark as sold
        </Button>
      </div>
    </div>
  );
};

export default UpdateVehicleDetail;
