import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getAuthHeaders = () => ({
  Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
});

const becomeADealerUrl = import.meta.env.VITE_STRAPI_API_BASE_URL;
const submitDealerFormUrl = import.meta.env.VITE_SCHEDULE_API_BASE_URL;

export const becomeADealerApi = createApi({
  reducerPath: "becomeADealerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: becomeADealerUrl,
  }),
  endpoints: (builder) => ({
    getBecomeADealer: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/become-a-dealers`,
          headers: getAuthHeaders(),
          params: {
            ...params,
          },
        };
      },
    }),

    getDealerTestimonials: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/dealer-testimonials`,
          headers: getAuthHeaders(),
          params: {
            ...params,
          },
        };
      },
    }),
  }),
});

export const submitDealerFormApi = createApi({
  reducerPath: "submitDealerFormApi",
  baseQuery: fetchBaseQuery({
    baseUrl: submitDealerFormUrl,
  }),
  endpoints: (builder) => ({
    submitDealerForm: builder.mutation({
      query: (payload) => {
        return {
          url: "/feedback/enquiry/",
          body: payload,
          method: "POST",
        };
      },
    }),
  }),
});

export const { useSubmitDealerFormMutation } = submitDealerFormApi;

export const { useGetBecomeADealerQuery, useGetDealerTestimonialsQuery } =
  becomeADealerApi;
